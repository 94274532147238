<template>
  <div class="level-up" @click="$emit('close')">
    <div class="row">
      <div class="col-12 text-center">
        <span class="level-text-2">
          Level Up!
        </span>
      </div>
      <div class="col-12 d-flex justify-content-center
       align-items-center pt-3 pb-3">
        <img class="level-badge-img flipInY slow infinite"
             :class="{animated : audioReady}"
             :src="level.asset_url"
             title="Level Badge"
             alt="badge" />
      </div>
      <div class="col-12 text-center"
           v-if="audioReady">
        <span class="level-text">
          Level {{ level.level }}
        </span>
      </div>
      <audio id="celeb-audio" ref="celebAudio">
        <source :src="audioSource">
      </audio>
    </div>
  </div>
</template>

<script>

  export default {
    name  : 'LevelUpModal',
    props : [
      'level',
    ],
    data() {
      return {
        audioReady   : false,
        audioOptions : {
          generic : [
            'generic_01.wav',
            'generic_02.wav',
          ],
          firstVideo : [
            'first_video_01.wav',
          ],
          afterVideo : [
            'after_video_01.wav',
            'after_video_02.wav',
            'after_video_03.wav',
          ],
        },
      }
    },
    computed : {
      audioSource() {
        return process.env.VUE_APP_ADMIN_BUCKET_BASE_URL +
          'celebration/' + this.selectedAudio;
      },
      selectedAudio() {
        if (localStorage.levelUpAction === 'FIRST_VIDEO') {
          return this.audioOptions.firstVideo[
            Math.floor(Math.random() * this.audioOptions.firstVideo.length)
          ];
        } else if (localStorage.levelUpAction === 'AFTER_VIDEO') {
          return this.audioOptions.afterVideo[
            Math.floor(Math.random() * this.audioOptions.afterVideo.length)
          ];
        }
        return this.audioOptions.generic[
          Math.floor(Math.random() * this.audioOptions.generic.length)
        ];
        
      },
    },
    mounted() {
      this.$refs.celebAudio.oncanplay = () => {
        this.audioReady = true;
        this.$refs.celebAudio.play();

      };
      this.$refs.celebAudio.onended = () => {
        setTimeout(() => {
          this.$emit('close');
        }, 500); // close after .5 second
      };
    },
    beforeDestroy() {
      localStorage.levelUpAction = 'GENERIC';
    },
  }
</script>

<style lang="scss" scoped>
  @import "../../assets/scss/components/modals/level-up";
</style>